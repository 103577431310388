
const UIkit = (window as any).UIkit;
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: 'RequestProductUpload',
})
export default class RequestProductUpload extends Vue {
    @Prop({ type: Array, required: true }) private files: any[];
    
    private maxFileSize: number = 8;

    private compFiles: any = [];
    private addFile(e: any) {
        const droppedFiles = e.dataTransfer.files;
        if(!droppedFiles) return;
        // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
        ([...droppedFiles]).forEach(f => {
            this.compFiles.push(f);
        });
    }

    private openFileUpload() {
        (this.$refs.file as any).click();
    }

    private addFileSelection() {
        const file = this.$refs.file as any;

        let sizeError = false;
        file.files.forEach((item: any) => {

            if ((item.size / 1024 / 1024) <= this.maxFileSize)
                this.compFiles.push(item);
            else
                sizeError = true;
        });

        if (sizeError)
        {
            UIkit.notification({
                message: this.$t('product.request-product-max-filesize', [this.maxFileSize, 'One or more images exeeds filesize limit of {0}Mb!']),
                status: 'warning',
                pos: 'top-center',
                timeout: 5000
            });
        }

        file.value = null;
    }

    private removeFile(index: number) {
        this.compFiles.splice(index, 1);
    }


    @Watch('compFiles', { immediate: true, deep: false })
    private onFileChange() {
        if(this.compFiles) {
            this.$emit('update-files', this.compFiles);
        }
    }
}
