
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProductSearchResultProduct } from '../../models/Product';
import ProductService from '../../services/ProductService';
import LoadingModule from '../../store/modules/LoadingModule';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';
import ContactForm from '../content/ContactForm.vue';
import RequestProductUpload from './RequestProductUpload.vue';

const ps: ProductService = new ProductService();

@Component({
    name: 'RequestProductButton',
    components: {
        ContactForm,
        RequestProductUpload,
    }
})
export default class RequestProductButton extends Vue {
    @Prop({ type: String, required: false, default: '' }) private sku: string;
    @Prop({ type: String, required: false, default: '' }) private name: string;
    @Prop({ type: String, required: false, default: '' }) private image: string;
    @Prop({ type: String, required: false, default: 'default' }) private size: string;

    get isLoading(): boolean {
        return LoadingModule.IS_LOADING;
    }

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get sizeClasses(): any {
        const retval: any = {
            input: '',
            button: '',
            iconRatio: '.8',
        };
        if (this.size !== 'medium') {
            retval.input = `uk-form-${this.size}`;
            retval.button = `uk-button-${this.size}`;
        }
        if (this.size === 'large') {
            retval.iconRatio = '1.1';
        }

        return retval;
    }

    get product(): ProductSearchResultProduct {
        return ProductDetailsModule.PRODUCT;
    }

    get productId(): string {
        if (this.sku.length)
            return this.sku;

        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.sku;
        }
        return this.product?.id;
    }

    get productName(): string {
        if (this.name.length)
            return this.name;

        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.name;
        }
        return this.product?.name;
    }

    get productImage(): string {
        if (this.image.length)
            return this.image;

        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.images?.[0] ?? this.product?.image;
        }
        return this.product?.image;
    }

    private modals: any = {
        requestform: false
    };

    private isSuccess: boolean = false;
    private isError: boolean = false;

    private payload: any = {
        product: '',
        name: '',
        email: '',
        phone: '',
        comment: '',
        files: [],
    };

    private updateFiles(files: any[]) {
        this.payload.files = files;
    }

    private reset() {
        this.isSuccess = false;
        this.isError = false;
    }

    private async send() {

        const result = await ps.sendProductRequest(this.payload);

        if (result === true)
            this.isSuccess = true;
        else
            this.isError = true;
    }

    private modalShow(section: string) {
        this.payload = {
            product: this.productId,
            name: '',
            email: '',
            phone: '',
            comment: '',
            files: [],
        }
        this.modals[section] = true;
    }

    private modalHide(section: string) {
        this.modals[section] = false;
        this.reset();
    }
}
