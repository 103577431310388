
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsName',
    components: {},
})
export default class DetailsVariantName extends Vue {
    @Prop() fallback: string;
    
    get name(): string {
        return ProductDetailsModule.SELECTED_VARIANT?.name;
    }
}
