
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { uuid } from '@/utils/StringUtils';
import { ContactFormRequestField } from '@/models/Content';
import { copy } from '@/utils/objectUtils';
import { Debounce } from 'vue-debounce-decorator';

@Component({
    components: {
    },
})
export default class ContactFormUpload extends Vue {
    @Prop({ type: Object, required: true }) field: ContactFormRequestField;
    @Prop({ type: Number, required: true }) index: number;

    model: any[] = null;
    invalidFileType: boolean = false;
    invalidFileSize: boolean = false;
    selectedFiles: File[];

    get acceptedFileTypes(): string[] {
        return this.field.acceptedfiletypes?.split(",").map(ft => {
            if (!ft.startsWith(".")) ft = "." + ft;
            return ft;
        }) ?? [];
    }

    get rules(): string {
        return this.field.required ? "required" : "";
    }

    get hideUpload(): boolean {
        if (!this.field.multiple && this.model)
            return true;
        else
            return false;
    }

    async onChange(e: any) {
        this.invalidFileType = false;
        this.invalidFileSize = false;
        const files = Array.from(e.target.files) as File[];
        if (files?.length > 0)
        {
            var invalidFileType = files.find((file: File) => {
                const fileextension = `.${file.name.split('.').pop()}`;
                if (!this.acceptedFileTypes.length || this.acceptedFileTypes.includes(fileextension.toLowerCase()))
                    return false;

                return true;
            });
            var invalidFileSize = files.find((file: File) => {
                if ((file.size / 1000) <= this.field.maxsize)
                    return false;

                return true;
            });

            if (invalidFileType)
            {
                (this.$refs.fileupload as any).value = null;
                this.invalidFileType = true;
            }
            else if (invalidFileSize)
            {
                (this.$refs.fileupload as any).value = null;
                this.invalidFileSize = true;
            }
            else 
            {
                if (this.selectedFiles?.length > 0)
                    files.forEach(f => {
                        if (!this.selectedFiles.includes(f))
                            this.selectedFiles.push(f);
                    })
                else
                    this.selectedFiles = files;

                this.model = this.selectedFiles.map((file: File) => {
                    const id = uuid();
                    return {
                        file: file,
                        filename: file.name,
                        filesize: Math.round(file.size / 1000),
                        id: id,
                    };
                });
            }
        }
    }

    async remove(id: string) {
        this.model = this.model.filter((file: any) => file.id !== id);
        if (this.model.length === 0)
            this.model = null;
        this.selectedFiles = this.model;
    }

    created() {
        this.model = copy(this.field.files ?? null);
    }

    @Watch("model", { deep: true })
    @Debounce(200)
    onModelChange() {
        this.$emit("change", { index: this.index, files: this.model });
    }
}
