
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ContactFormRequestField } from '@/models/Content';
import { copy } from '@/utils/objectUtils';
import { Debounce } from 'vue-debounce-decorator';

@Component({
    components: {
    },
})
export default class ContactFormChecklist extends Vue {
    @Prop({ type: Object, required: true }) field: ContactFormRequestField;
    @Prop({ type: Number, required: true }) index: number;

    model: any[] = null;

    created() {
        this.model = copy(this.field.checklistitems ?? null);
    }

    @Watch("model", { deep: true })
    @Debounce(200)
    onModelChange() {
        this.$emit("change", { index: this.index, checklistitems: this.model });
    }
}
