const UIkit = window.UIkit;

export default function () {
    const elementSelector = '#homepageSlideshow';
    const node = document.querySelector(elementSelector);
    if (node !== null) {
        let isPlaying = true;
        const toggleSelector = '#homepageSlideshowPause';

        UIkit.util.on(document, 'click', toggleSelector, () => {
            console.log('slider pause')
            if (isPlaying) {
                UIkit.slideshow(elementSelector).stopAutoplay();
            }
            else {
                UIkit.slideshow(elementSelector).startAutoplay();
            }
            isPlaying = !isPlaying;
        });
    }

    function cover() {
        const items = document.querySelectorAll(".cbx-content-slider-item-video-embed iframe");
        items.forEach(item => {
            const width = item.width;
            const height = item.height;
            const parent = item.parentElement;
            const parentWidth = parent.offsetWidth;
            const parentHeight = parent.offsetHeight;
 
            const aspectRatio = height / width;
            let newWidth = parentWidth,
                newHeight = newWidth * aspectRatio;

            if (newHeight < parentHeight)
                newHeight = parentHeight, newWidth = newHeight / aspectRatio;

            item.style.width = newWidth + 'px';
            item.style.height = newHeight + 'px';
        });
    }

    document.addEventListener("DOMContentLoaded", function () {        
        setTimeout(cover(), 100);
        window.addEventListener("resize", cover);
    });
}