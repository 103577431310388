
import { copy } from "@/utils/objectUtils";
import { Debounce } from "vue-debounce-decorator";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ContactFormRequestField } from '../../models/Content.d';
import ContactFormKeyValueItem from "./ContactFormKeyValueItem.vue";

@Component({
    name: "ContactFormKeyValueField",
    components: {
        ContactFormKeyValueItem,
    }
})
export default class ContactFormKeyValueField extends Vue {

    @Prop({ type: Object, required: true }) private field: ContactFormRequestField;
    @Prop({ type: Number, required: true }) private index: number;

    private model: any[] = [{ key: '', value: '' }];

    private addKeyValue() {
        this.model.push({ key: "", value: ""});
    }

    private remove(index: number) {
        this.model.splice(index, 1);
    }

    private created() {
        this.model = copy(this.field.keyvalues)
    }

    @Watch("model", { deep: true })
    @Debounce(200)
    private onModelChange() {
        this.$emit("change", { index: this.index, keyvalues: this.model });
    }
}
