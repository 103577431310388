
import { Component, Vue, Prop } from "vue-property-decorator";
import { ContactFormRequestField } from '../../models/Content.d';

@Component({
    name: "ContactFormKeyValueItem",
    components: {

    }
})
export default class ContactFormKeyValueItem extends Vue {

    @Prop({ type: Object, required: true }) private field: ContactFormRequestField;
    @Prop({ type: Object, required: true }) private keyValue: any;
    @Prop({ type: Number, required: true }) private index: number;
    @Prop({ type: Boolean, required: true, default: true }) private removable: boolean;

    get rules() {
        const rules = [];

        if (this.field.required)
            rules.push('required');

        return rules.join('|');
    }

    private remove() {
        this.$emit("remove", this.index);
    }
}
