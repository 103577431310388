
import { copy } from "@/utils/objectUtils";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ContactFormRequest, ContactFormRequestField } from '../../models/Content.d';
import ContentService from '../../services/ContentService';
import ContactFormChecklist from "./ContactFormChecklist.vue";
import ContactFormKeyValueField from "./ContactFormKeyValueField.vue";
import ContactFormUpload from "./ContactFormUpload.vue";

const contentService: ContentService = new ContentService();

@Component({
    name: "ContactForm",
    components: {
        ContactFormChecklist,
        ContactFormUpload,
        ContactFormKeyValueField,
    }
})
export default class ContactForm extends Vue {

    @Prop({ type: String, required: false, default: null }) private emailTo: string;
    @Prop({ type: String, required: true }) private emailSubject: string;
    @Prop({ type: String, required: false, default: null }) private title: string;
    @Prop({ type: String, required: false, default: null }) private description: string;
    @Prop({ type: String, required: true }) private buttonLabel: string;
    @Prop({ type: String, required: true }) private successMessage: string;
    @Prop({ type: Array, required: true }) private fields: ContactFormRequestField[];
    @Prop({ type: Boolean, required: false, default: false }) private emailSendACopy: boolean;
    @Prop({ type: String, required: false, default: "" }) private emailSendACopySubject: string;
    @Prop({ type: String, required: false, default: "" }) private emailSendACopyMessage: string;
    @Prop({ type: Boolean, required: false, default: false }) askForConsent: boolean;
    @Prop({ type: String, required: false, default: "" }) consentText: string;
    @Prop({ type: String, required: false, default: "" }) consentPageUrl: string;

    private model: ContactFormRequestField[] = [];
    consentGiven: boolean = false;
    private visible: boolean = false;
    private isSuccess: boolean = false;
    private isError: boolean = false;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get showHeader() {
        return this.title || this.description;
    }

    private getRules(field: ContactFormRequestField) {
        const rules = [];

        if (field.required)
            rules.push('required');

        if (field.type === 'email')
            rules.push('email');

        return rules.join('|');
    }

    private onKeyValuesChange(input: any) {
        this.model[input.index].keyvalues = input.keyvalues;
    }

    onUploadChange(input: any) {
        this.model[input.index].files = input.files;
    }

    onChecklistChange(input: any) {
        this.model[input.index].checklistitems = input.checklistitems;
    }

    private reset() {
        this.isSuccess = false;
        this.isError = false;
    }

    private async send() {

        const payload: ContactFormRequest = {
            emailto: this.emailTo,
            emailsubject: this.emailSubject,
            emailsendacopy: this.emailSendACopy,
            emailsendacopysubject: this.emailSendACopySubject,
            emailsendacopymessage: this.emailSendACopyMessage,
            fields: this.model,
        };

        const result = await contentService.sendContactRequest(payload);

        if (result === true)
            this.isSuccess = true;
        else
            this.isError = true;
    }

    private created() {
        this.model = copy(this.fields);

        this.model.map(field => {
            if (field.type === "keyvalue" && !field.keyvalues?.length)
                field.keyvalues = field.required ? [{ key: "", value: "" }] : [];
            return field;
        });
    }
}
