
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsVariantItemnumber',
    components: {},
})
export default class DetailsVariantItemnumber extends Vue {
    @Prop({ type: String, required: true }) private itemNoText: string;
    @Prop() fallback: string;

    get itemnumber(): string {
        return ProductDetailsModule.SELECTED_VARIANT?.sku;
    }

    get hidden(): boolean {
        return (
            ProductDetailsModule.VARIANTS?.length > 0 && CbxVariablesModule.VARIABLES.variantDisplayOptions.productlist
        );
    }
}
