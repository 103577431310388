
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayPriceInfo } from '../../models/Product';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsTotalPriceIncDuties',
    components: {
    },
})
export default class DetailsTotalPriceIncDuties extends Vue {
    @Prop() totalTxt: string;
    @Prop() exvatText: string;
    @Prop() incvatText: string;

    get price(): DisplayPriceInfo {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.totalpriceincduties;
        else 
            return ProductDetailsModule.PRODUCT?.totalpriceincduties;
    } 

    get unit(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.unit;
        else 
            return ProductDetailsModule.PRODUCT?.unit;
    } 
}
